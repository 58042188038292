import * as tracking	from "@geotoura/common/privacy/tracking";

export const territorybox = ():void => {
	const prev		= document.querySelector<HTMLButtonElement>(".js-territorybox-prev");
	const next		= document.querySelector<HTMLButtonElement>(".js-territorybox-next");
	const wrapper	= document.querySelector<HTMLElement>(".js-territorybox-wrapper");

	if (wrapper === null || prev === null|| next === null) return;

	// Size of one territorybox + 1x margin
	const scrollOffset = 880;

	prev.addEventListener("click", () => {
		if (wrapper.scrollLeft <= scrollOffset) prev.classList.toggle("territorybox-button-inactive", true);

		wrapper.scrollBy({
			left:		-scrollOffset,
			behavior:	"smooth",
		});

		next.classList.toggle("territorybox-button-inactive", false);

		tracking.gaSendEvent({
			action:		"territorybox-navigate",
			category:	"territorybox",
			label:		"territorybox-previousbutton",
			value:		1,
		});
	});

	next.addEventListener("click", () => {
		if (wrapper.scrollWidth - wrapper.offsetWidth - wrapper.scrollLeft < scrollOffset) {
			next.classList.toggle("territorybox-button-inactive", true);
		}

		wrapper.scrollBy({
			left:		scrollOffset,
			behavior:	"smooth",
		});

		prev.classList.toggle("territorybox-button-inactive", false);

		tracking.gaSendEvent({
			action:		"territorybox-navigate",
			category:	"territorybox",
			label:		"territorybox-nextbutton",
			value:		1,
		});
	});
};
