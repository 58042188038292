import { Nullable }		from "ts-base/nullable";

import { AsUrlParameter }	from "@geotoura/shared/representation";

export const getParameter = <T>(asUrlParameter:AsUrlParameter<T>):T|null =>
	Nullable.then(getRawParameter(asUrlParameter.name))(asUrlParameter.fromUrlParameter);

// TODO repr do not export this
export const getRawParameter = (name:string):string|null =>
	rawParameters().get(name);

// TODO repr do not export this
export const rawParameters = ():URLSearchParams =>
	new URL(window.location.href).searchParams;
