import * as commonModel	from "@geotoura/shared/commonModel";

export const tag = <K extends keyof HTMLElementTagNameMap>(tagName:K, attributes:Partial<HTMLElementTagNameMap[K]>, children?:ReadonlyArray<HTMLElement>):HTMLElementTagNameMap[K] => {
	const result = document.createElement(tagName);
	for (const key in attributes) {
		if (!attributes.hasOwnProperty(key))	continue;
		const value	= attributes[key];
		if (value === undefined)				continue;
		// this is as good as it gets: we don't know the exact element type, so we don't know the attribute types either
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		result[key] = attributes[key] as any;
	}
	if (children !== undefined) {
		for (const child of children) {
			result.appendChild(child);
		}
	}
	return result;
};

export const parseHtml	= (str:commonModel.HtmlSnippet):Document	=>
	new DOMParser().parseFromString(str, "text/html");

export const parseXml	= (str:string):XMLDocument	=>
	new DOMParser().parseFromString(str, "application/xml");
