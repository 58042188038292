export const topButton = ():void => {
	const topButton = document.querySelector<HTMLElement>(".js-top-button");

	if (topButton === null)	return;

	topButton.addEventListener("click", () => window.scrollTo({ top: 0, left: 0, behavior: "smooth" }));

	let lastPosition = 0;
	window.addEventListener("scroll", () => {
		const currentPosition = window.scrollY ;
		topButton.classList.toggle("fadeIn", currentPosition < lastPosition);
		// set class for use in booking tablet that a page is scrolled down
		document.body.classList.toggle("scrolled", currentPosition > 400);
		lastPosition = currentPosition;
	});
};
