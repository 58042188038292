import { Nullable }			from "ts-base/nullable";
import { Fn }				from "ts-base/fn";
import { Html, html }		from "ts-base/web/html";

import { Logger }			from "@geotoura/shared/logger";
import * as i18n			from "@geotoura/shared/i18n";
import { AffiliateId }		from "@geotoura/shared/data/affiliateId";
import { GoogleClickId }	from "@geotoura/shared/data/googleClickId";
import { CookieSetting }	from "@geotoura/shared/data/cookieSetting";

import * as dom				from "@geotoura/common/util/dom";
import * as url				from "@geotoura/common/util/url";
import * as cookie			from "@geotoura/common/util/cookie";
import * as variants		from "@geotoura/common/variants";
import * as Server			from "@geotoura/common/Server";

import { Messages }			from "@geotoura/common/privacy/locale";
import { loadTrackers, unloadTrackers }		from "@geotoura/common/privacy/tracking";

const logger	= Logger.create("cookieWarning");

export const cookieWarning = (languageCode:i18n.LanguageCode):void => {
	const settingsCookie	= cookie.get(CookieSetting.asCookie);
	const msg				= Messages.of[languageCode];

	const cookieDiv	= document.querySelector<HTMLElement>(".cookie-backdrop");

	if (cookieDiv === null) {
		logger.error("No cookie backdrop found on page");
		return;
	}

	addSettingsButton(msg.title);

	cookieDiv.innerHTML =
		Html.renderString(html`
			<div class="cookie-message">
				<div class="cookie-intro">
					<strong>${msg.title}:</strong> ${msg.intro.pre}
					<a class="textlink" target="_blank" href="${msg.intro.link.href}">${msg.intro.link.text}</a>
					${msg.intro.post}
				</div>
				<form>
				<div class="cookie-checkboxrow">
					<div class="cookie-checkbox">
						<input class="checkbox" type="checkbox" id="cookie-cb-essential" value="essential" name="cookie-cb-essential" disabled checked>
						<label class="checkbox-label" for="cookie-cb-essential">
							<span></span>
							<span>${msg.essential}</span>
						</label>
					</div>
					<div class="cookie-checkbox">
						<input class="checkbox" type="checkbox" id="cookie-cb-all" value="all" name="cookie-cb-all">
						<label class="checkbox-label" for="cookie-cb-all">
							<span></span>
							<span>${msg.nonessential.pre}
							<a class="textlink" target="_blank" href="${msg.nonessential.link.href}">${msg.nonessential.link.text}</a>
							${msg.nonessential.post}</span>
						</label>
					</div>
				</div>
				<div class="cookie-buttonrow">
					<button type="submit" class="cookie-button cookie-save-settings ghost-button">${msg.saveSettings}</button>
					<button type="button" class="cookie-button cookie-accept-all primary-button">${msg.acceptAll}</button>
				</div>
				<a class="textlink cookie-textlink-light" target="_blank" href="${msg.imprint.link.href}">
					${msg.imprint.link.text}
				</a>
				</form>
			</div>
		`);

	const form					= Nullable.unsafeGet(cookieDiv.querySelector<HTMLFormElement>("form"));
	const acceptAllButton		= Nullable.unsafeGet(document.querySelector<HTMLElement>(".cookie-accept-all"));
	const acceptAllCheckbox		= Nullable.unsafeGet(document.querySelector<HTMLInputElement>("#cookie-cb-all"));
	// const cookieSaveSettings	= document.querySelector('.cookie-save-settings');

	const cookieDialog			= Nullable.unsafeGet(document.querySelector<HTMLElement>(".cookie-backdrop"));
	const cookieDialogVisible	= (visible:boolean):void => { cookieDialog.classList.toggle("off", !visible); };

	const displaySettingsButtons = Array.from(document.querySelectorAll(".js-display-cookie-settings"));

	displaySettingsButtons.forEach((button) => {
		button.addEventListener("click", () => cookieDialogVisible(true));
	});

	acceptAllButton.addEventListener("click", () => {
		// logger.info("cookie-form acceptall");
		const value = "all";
		cookie.set(CookieSetting.asCookie, value);
		acceptAllCheckbox.checked = true;
		cookieDialogVisible(false);
		loadTrackersAndAffiliate();
		// TODO repr make this typesafe?
		void Server.setSettings({ name: CookieSetting.asCookie.name, content:	value });
	});

	form.addEventListener("submit", (ev) => {
		ev.preventDefault();

		const value = acceptAllCheckbox.checked ? "all" : "essential";
		// logger.info("cookie-form submit", setting);
		if (value === "all")	loadTrackersAndAffiliate();
		else					clearTrackingCookies();

		cookie.set(CookieSetting.asCookie, value);
		// TODO repr make this typesafe?
		void Server.setSettings({ name: CookieSetting.asCookie.name, content:	value });
		cookieDialogVisible(false);
	});

	// Neubesucher, kein Cookie, Cookie-Dialog zeigen
	if (settingsCookie === null) {
		cookieDialogVisible(true);
	}
	else if (settingsCookie === "all") {
		// Cookie-Einstellung all
		logger.info("cookie setting: all");
		acceptAllCheckbox.checked = true;
		loadTrackersAndAffiliate();
	}
	else if (settingsCookie === "essential") {
		// Cookie-Einstellung essential
		logger.info("cookie setting: essential ");
	}
	else {
		Fn.impossible(settingsCookie);
	}
};

const loadTrackersAndAffiliate = ():void => {
	// logger.info("loadTrackers");
	const affiliateId	= url.getParameter(AffiliateId.asUrlParameter);
	if (affiliateId !== null) {
		cookie.set(AffiliateId.asCookie, affiliateId);
	}
	const googleClickId = url.getParameter(GoogleClickId.asUrlParameter);
	if (googleClickId !== null)	{
		cookie.set(GoogleClickId.asCookie, googleClickId);
	}
	variants.setup();
	loadTrackers();
};

const clearTrackingCookies = ():void => {
	// const blackList = ['_ga', '_gid', '_gcl_au', '_uetvid', '_uetsid', 'affiliateId'];
	const whiteList = [ CookieSetting.asCookie.name ];

	for (const name of cookie.names()){
		if (!whiteList.includes(name)) {
			cookie.remove(name);
		}
	}
	unloadTrackers();
};

const addSettingsButton = (title:string):void => {
	const footerMenu		= document.querySelector<HTMLElement>("#menu-footer-navigation");
	if (footerMenu === null) {
		// expected in all site templates without the standard footer (page-planner.php)
		logger.warn("Cookie settings cannot be added because the id #menu-footer-navigation was not found on page.");
		return;
	}
	const settingsButton	= dom.tag("button",	{ className: "js-display-cookie-settings", textContent: title });
	const listItem			= dom.tag("li",		{ className: "menu-item" });
	listItem.appendChild(settingsButton);
	footerMenu?.appendChild(listItem);
};
