import { Arrays }	from "ts-base/arrays";
import { Objects }	from "ts-base/objects";
import { NotNull }	from "ts-base/notNull";
import { Nullable }	from "ts-base/nullable";

import { AsCookie, AsString }	from "@geotoura/shared/representation";

import * as cookie		from "@geotoura/common/util/cookie";

//-----------------------------------------------------------------------------

const experiments	= {
	foo:	{
		purpose:	"encourage sufficiently flexible parsing of tracking strings",
		start:		"TODO",
		end:		"TODO",
		variants:	[ "A", "B" ],
	},
} as const;

type Experiments	= typeof experiments;

// the chosen variant for each experiment
type VariantsChoice	= Readonly<{
	[K in keyof Experiments]:Experiments[K]["variants"][number]
}>;

// the chosen variant for each experiment or null if unknown
// (due to a missing cookie or because the experiment is new and not in the cookie yet)
export type VariantsChosen = Readonly<{
	[K in keyof VariantsChoice]:VariantsChoice[K]|null
}>;

//-----------------------------------------------------------------------------

// returns the chosen variant (or null without a cookie) for each experiment
export const get = ():VariantsChosen =>
	cookie.get(asCookie) ?? nothingChosenYet;

// stores chosen variants in our cookie
export const setup = ():void => {
	// readCookie removes obsolete experiments
	cookie.set(asCookie, chooseForMissingExperiments(get()));
};

// tracking string containing all chosen variants to be sent to GA
export const trackingString = ():string =>
	chosenToKvs(get())
	.map(({ key, value }) => `${key}:${value}`)
	.join(", ");

//-----------------------------------------------------------------------------

const experimentIds = Objects.unsafeTypedKeys(experiments);

const nothingChosenYet:VariantsChosen	=
	Object.fromEntries(experimentIds.map((key) => [ key, null ])) as VariantsChosen;

// randomly selects a variant for each experiment we have not previously chosen one
const chooseForMissingExperiments	= (chosen:VariantsChosen):VariantsChoice	=>
	Object.fromEntries(
		Objects.unsafeTypedEntries(chosen)
		.map(([ key, value ]) => [
			key,
			value ?? randomEnumValue(experiments[key].variants),
		])
	) as VariantsChoice;

const kvsToChosen	= (kvs:ReadonlyArray<Kv>):VariantsChosen =>
	Object.fromEntries(
		experimentIds.map((experimentId) => {
			const raw		= kvs.find(kv => kv.key === experimentId)?.value ?? null;
			const variant	= Nullable.then(raw)(validateEnumValue(experiments[experimentId].variants));
			return [ experimentId, variant ];
		})
	) as VariantsChosen;

/*
const choiceToKvs	= (choice:VariantsChoice):ReadonlyArray<Kv> =>
	Objects.unsafeTypedEntries(choice)
	.map(([ key, value ]) => ({ key, value }));
*/

const chosenToKvs	= (chosen:VariantsChosen):ReadonlyArray<Kv> =>
	Objects.unsafeTypedEntries(chosen)
	.map(([ key, value ]) =>
		value !== null ? { key, value } : null
	)
	.filter(NotNull.is);

//-----------------------------------------------------------------------------

/*
// NOTE we actually only use this for the more strict VariantsChoice but MetaCookie needs this to take the same type as decodeCookie returns
// therefore we use chosenToKvs instead of choiceToKvs
const encodeCookie	= (variants:VariantsChosen):string	=>
	encodeURIComponent(unparseKvs(chosenToKvs(variants)));

const decodeCookie	= (s:string):VariantsChosen	=>
	kvsToChosen(parseKvs(decodeURIComponent(s)));
*/

const asString:AsString<VariantsChosen>	= {
	fromString:	(s:string):VariantsChosen			=> kvsToChosen(parseKvs(decodeURIComponent(s))),
	// NOTE we actually only use this for the more strict VariantsChoice but MetaCookie needs this to take the same type as decodeCookie returns
	// therefore we use chosenToKvs instead of choiceToKvs
	toString:	(variants:VariantsChosen):string	=> encodeURIComponent(unparseKvs(chosenToKvs(variants))),
};

const asCookie:AsCookie<VariantsChosen>	= {
	name:				"variants",
	timeToLive:			{ years: 1 },
	fromCookieValue:	asString.fromString,
	toCookieValue:		asString.toString,
};

//-----------------------------------------------------------------------------

// safe because semicolon is escaped in encodeURIComponent
const separator	= ";";

const parseKvs	= (s:string):ReadonlyArray<Kv>	=>
	s
	.split(separator)
	.map(parseKv)
	.filter(NotNull.is);

const unparseKvs	= (kvs:ReadonlyArray<Kv>):string	=>
	kvs
	.map(unparseKv)
	.join(separator);

//-----------------------------------------------------------------------------

type Kv = Readonly<{ key:string, value:string }>;

const parseKv = (s:string):Kv|null => {
	// eslint-disable-next-line @typescript-eslint/prefer-regexp-exec
	const results	= s.match(/^([^_]*)_([^_]*)$/);
	if (results === null)	return null;

	const [ _all, key, value ]	= results;
	if (key === undefined || value === undefined)	return null;

	return { key, value };
};

const unparseKv	= (kv:Kv):string	=>
	`${kv.key}_${kv.value}`;

//-----------------------------------------------------------------------------

const validateEnumValue	= <T extends string>(allowed:ReadonlyArray<T>) => (value:string):T|null	=> {
	const tval	= value as T;
	return allowed.includes(tval) ? tval : null;
};

const randomEnumValue	= <T>(items:ReadonlyArray<T>):T	=> {
	const item	= Arrays.randomSelect(items);
	if (item === undefined)	throw new Error("broken experiment, number of variants is unexpectedly 0");
	return item;
};
