
import * as sharedLocale	from "@geotoura/shared/sharedLocale";
import * as i18n			from "@geotoura/shared/i18n";

import { messages as de } from "@geotoura/common/privacy/locale_de";
import { messages as en } from "@geotoura/common/privacy/locale_en";
import { messages as it } from "@geotoura/common/privacy/locale_it";

export type Messages = Readonly<{
	title:			string,
	intro:			sharedLocale.EmbeddedLink,
	essential:		string,
	nonessential:	sharedLocale.EmbeddedLink,
	saveSettings:	string,
	acceptAll:		string,
	imprint:		sharedLocale.EmbeddedLink,
}>;

export namespace Messages {
	export const of:i18n.Localized<Messages>	= { de, en, it };
}
