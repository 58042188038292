import * as tracking	from "@geotoura/common/privacy/tracking";

export const newsletterbox = ():void => {
	const newsletterboxButton		= document.querySelector(".js-newsletterbox-opener");
	const newsletterboxDialog		= document.querySelector(".js-newsletterbox-dialog");
	const newsletterboxCloseButton	= document.querySelector(".js-newsletterbox-close");

	if (newsletterboxButton === null || newsletterboxDialog === null|| newsletterboxCloseButton === null)	return;

	newsletterboxButton.addEventListener("click", () => {
		newsletterboxDialog.classList.add("open");
		tracking.gaSendEvent({
			action:		"newsletter-open",
			category:	"newsletter",
			label:		"newsletter-pagebutton",
			value:		1,
		});
	});
	newsletterboxCloseButton.addEventListener("click", () => {
		newsletterboxDialog.classList.remove("open");
	});
};
