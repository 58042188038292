import { AsCookie, AsString }	from "@geotoura/shared/representation";

export type CookieSetting	= "all" | "essential";

export namespace CookieSetting {
	export const asString:AsString<CookieSetting>	= {
		fromString:	(it:string):CookieSetting|null	=> it === "all" || it === "essential" ? it : null,
		toString:	(it:CookieSetting):string		=> it,
	};

	export const asCookie:AsCookie<CookieSetting>	= {
		name:				"cookieSetting",
		timeToLive:			{ years: 1 },
		fromCookieValue:	asString.fromString,
		toCookieValue:		asString.toString,
	};
}
