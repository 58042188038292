import { Branded }	from "ts-base/branded";

import { AsCookie, AsUrlParameter, AsString }	from "@geotoura/shared/representation";

export type GoogleClickId	= Branded<string, "GoogleClickId">;

export namespace GoogleClickId {
	export const asString:AsString<GoogleClickId>	= {
		fromString:	(it:string):GoogleClickId|null	=> [ ...it ].length <= 100 ? Branded.brand<GoogleClickId>(it) : null,
		toString:	(it:GoogleClickId):string		=> it.toString(),
	};

	export const asCookie:AsCookie<GoogleClickId>	= {
		name:				"googleClickId",
		timeToLive:			{ months: 1 },
		fromCookieValue:	asString.fromString,
		toCookieValue:		asString.toString,
	};

	export const asUrlParameter:AsUrlParameter<GoogleClickId>	= {
		name:				"gclid",
		fromUrlParameter:	asString.fromString,
	};
}
