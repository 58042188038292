import { Branded }	from "ts-base/branded";

import { AsCookie, AsUrlParameter, AsString }	from "@geotoura/shared/representation";

export type AffiliateId	= Branded<number, "AffiliateId">;

export namespace AffiliateId {
	export const asString:AsString<AffiliateId>	= {
		fromString:	(it:string):AffiliateId|null	=> /^(0|[1-9][0-9]*)$/.test(it) ? Branded.brand<AffiliateId>(parseInt(it)) : null,
		toString:	(it:AffiliateId):string			=> it.toString(),
	};

	export const asCookie:AsCookie<AffiliateId>	= {
		name:				"affiliateId",
		timeToLive:			{ months: 1 },
		fromCookieValue:	asString.fromString,
		toCookieValue:		asString.toString,
	};

	export const asUrlParameter:AsUrlParameter<AffiliateId>	= {
		name:				"affiliateId",
		fromUrlParameter:	asString.fromString,
	};
}
